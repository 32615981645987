import _ from 'lodash'
import { optionalPath } from './utils/path'
import { objToQueryParamsString } from './helpers/functionalUtils'

const prodURL = 'https://sync.appfluence.com/'
let _baseURL = prodURL

const isWebOnReleaseMode = window && window.location
const isReactNative = navigator.product === 'ReactNative'
if (!isReactNative && isWebOnReleaseMode) {
  _baseURL = window.location.origin + '/'
}

export const BASE_URL = _baseURL
const _prependBaseURL = u => BASE_URL + u

const _wsBaseURL = _.replace(_baseURL, /^http/, 'ws')
const removeProtocol = url => _.replace(url, /^.*:\/\//, '')
const _prependWsBaseURL = u => _wsBaseURL + u

const GRAPH_BASE_URL = 'https://graph.microsoft.com/'
const _prependGraphBaseURL = u => GRAPH_BASE_URL + u

export let PM_API_URLS = {
  CONFIG: 'config/',
  GET_TOKEN: 'o/token/',
  ME: 'api/v1/me/',
  LICENSE_STATUS: 'manage/status/',
  INBOX_PLUS: 'api/v1/me/inboxplus/',
  IS_LOGGED_IN: 'api/v1/me/isloggedin/',
  SIGN_OUT: 'api/v1/me/logout/',
  COLLABORATORS: 'api/v1/me/collaborators/',
  ONE_ON_ONE_HUB_COLLABORATORS: 'api/v1/me/oneonone_hub_collaborators/',
  MS_COLLABORATORS: 'api/v1/me/ms_collaborators/',
  PLATFORMS: 'api/v1/me/platforms/',
  COMMENT: 'api/v1/comment/${ id }/',
  POST_COMMENT: 'api/v1/comment/',
  ALL_COMMENTS: 'api/v2/comment/',
  ITEM_COMMENTS: 'api/v1/item/${ id }/comments/',
  RECENTLY_VISITED_COMMENTS: 'api/v1/user/${ id }/recently_visited/',
  RECENTLY_TYPED_COMMENTS: 'api/v1/user/${ id }/recently_typed/',
  ATTENTION_NEEDED: 'api/v2/item/attention/',
  ALL_ITEMS: 'api/v2/item/',
  ITEM: 'api/v2/item/${ id }/',
  ITEM_SUMMARIES: 'api/v2/item_summary/',
  ITEM_COPY: 'api/v1/item/${ id }/copy/',
  INVITE_FOLLOWER_TO_ITEM: 'api/v2/item/${ id }/invite/',
  UNINVITE_FOLLOWER_TO_ITEM: 'api/v2/item/${ id }/uninvite/',
  ATTACH_EMAIL_TO_ITEM: 'api/v2/item/${ id }/attach_email/',
  MAKE_ITEM_PUBLIC: 'api/v2/item/${ id }/publish_item/',
  MAKE_ITEM_PRIVATE: 'api/v2/item/${ id }/unpublish_item/',
  ALL_PROJECTS: 'api/v1/project/',
  ALL_PROJECT_SUMMARIES: 'api/v1/project_summary/',
  SORT_PROJECTS: 'api/v1/project/order/',
  USER_GROUPS: 'api/v1/group/',
  PROJECT: 'api/v2/project/${ idd }/',
  FOLLOWED_ITEMS: 'api/v2/project/${ idd }/followed_items/',
  ITEMS_IN_PROJECT: 'api/v1/project/${ idd }/items/', //Take care with v2!! this may cause problem due to the way we paginate
  ITEM_SUMMARIES_IN_PROJECT: 'api/v2/project/${ idd }/item_summaries/', //Use as lodash template https://lodash.com/docs/4.17.10#template
  PROJECT_READONLY_TOKEN: 'api/v1/project/${ idd }/readonly/', //Use as lodash template https://lodash.com/docs/4.17.10#template
  PROJECT_DUPLICATE: 'api/v2/project/${ idd }/copy_project/',
  RELATED_ITEMS: 'api/v1/item/${ id }/related_items/',
  POST_FILE: 'api/v1/file/',
  FILE: 'api/v1/file/${ id }/',
  FILE_ONEDRIVE_FOLDER: 'api/v1/item/${ id }/onedrive_folder/',
  DEVICE_GCM: 'api/v1/device/gcm/',
  DEVICE_APNS: 'api/v1/device/apns/',
  SEARCH: 'api/v1/me/search/',
  GRAPH_PROXY: 'graph_api/',
  SHARE_NEW_TEAMS_TAB: 'graph_api/share_teams_tab/',
  GRAPH_EXCHANGE_TOKEN: 'graph_api/exchange_token/',
  TEAMS_CONVERSATION_MEMBERS: 'agent/teams_conversation_members/',
  TEAMS_MATCHED_MEETING: 'agent/teams_matched_meeting/',
  CONNECTORS: 'api/v1/connector/',
  CONNECTOR: 'api/v1/connector/${ id }/',
  DISABLE_CONNECTOR: 'api/v1/connector/${ id }/disable/',
  TABS: 'api/v1/teams_tab/',
  TAB: 'api/v1/teams_tab/${ id }/',
  DISABLE_TAB: 'api/v1/teams_tab/${ id }/disable/',
  TAG: 'api/v1/tag/',
  RENAME_TAG: 'api/v1/tag/${ id }/rename/',
  ITEM_TAGS: 'api/v2/item/tags/',
  PROJECT_TAGS: 'api/v2/project/tags/',
  ALL_SHARING_REQUESTS: 'api/v1/sharing_request/',
  SHARING_REQUEST: 'api/v1/sharing_request/${ id }/',
  REQUEST_ACCESS: 'api/v2/project/${ idd }/request_access',
  TRACK: 'pm_tracking/',
  CUSTOM_PROJECT_TEMPLATES: 'api/v1/template/',
  CUSTOM_PROJECT_TEMPLATE: 'api/v1/template/${ id }',
  INSTANTIATE_CUSTOM_PROJECT_TEMPLATES: 'api/v1/template/${ id }/instantiate',
  PUBLIC_PROJECT_TEMPLATE_CATEGORIES: 'api/v1/public_template/categories',
  PUBLIC_PROJECT_TEMPLATES: 'api/v1/public_template/',
  PUBLIC_PROJECT_TEMPLATE: 'api/v1/public_template/${ id }',
  INSTANTIATE_PUBLIC_PROJECT_TEMPLATES: 'api/v1/public_template/${ id }/instantiate',
  CREATE_CUSTOM_TEMPLATE_FROM_PROJECT: 'api/v1/project/${ id }/create_template/',
  MEETINGS: 'api/v1/meeting/',
  MEETING: 'api/v1/meeting/${ id }/',
  MEETING_ITEMS: 'api/v1/meeting/${ id }/items/',
  MEETINGS_UPCOMING: 'api/v1/meeting/upcoming/',
  MEETINGS_LOAD: 'api/v1/meeting/load/',
  MEETING_NEAREST: 'api/v1/meeting/${ id }/nearest/',
  MEETING_RELOAD_NOTES: 'api/v1/meeting/${ id }/reload_notes/',
  ASSOCIATED_LINKS: 'api/v2/item/${ id }/links/',
  DELETE_ASSOCIATED_LINK: 'api/v2/item/${ id }/links/${ linkID }/',
  REMINDER: 'api/v1/reminder/${ id }/',
  ALL_REMINDERS: 'api/v1/reminder/',
  UPDATE_PROJECT_ROLE: 'api/v2/project/${ idd }/update_role/',
  INBOX_ADDRESSES: 'api/v2/project/${ idd }/inbox_addresses/',
  NOTIFICATIONS: 'api/v1/notification/',
  NOTIFICATION: 'api/v1/notification/${ id }/',
  CREATE_CALENDAR_LINK: 'pmweb/feeds/calendar_subscription/${ id }/',
  MARK_ITEMS_AS_READ: 'api/v1/item/read/',
  SYNC_TZ_OFFSET: 'sync/update_tz/',
  ITEM_CONFLICTS: 'api/v1/item/${ id }/conflicts/',
  PROJECT_SUBSCRIPTIONS: 'api/v2/project/${ id }/subscriptions/',
  CUSTOM_FILTERS: 'api/v1/me/filters/',
  CUSTOM_FILTER: 'api/v1/me/filters/${ id }/',
  AI_NOTES: 'api/v1/item/definition/',
  CREATE_PROJECT_AI: 'api/v1/project/create_from_goal/',
  GOAL_EXAMPLES: 'sync/goals/',
}

export let SERVER_URLS = {
  CREATE_ACCOUNT: 'accounts/register/?ref=office365',
  RESET_PASSWORD: 'accounts/password/reset/',
  SIGN_OUT: 'accounts/logout/',
  PERSONAL_INFO: 'accounts/user/',
  BUSINESS_REQUIRED: 'accounts/req_business/?embedded',
  STORAGE: 'accounts/storage/',
  MANAGE_DOWNLOADS: 'manage/downloads/',
  MANAGE_ACCOUNT: 'manage/account/',
  MANAGE_SUBSCRIPTIONS: 'manage/subscriptions/',
  USER_GROUPS: 'accounts/team/list/',
  SETUP_LINKS: 'accounts/setup/',
  CHANGE_AVATAR: 'avatar/change/',
  CONTACT_US: 'accounts/contact/?os=web',
  TEAMS_MEETING: 'office365/app/index/teams/meeting/',
  ONE_ON_ONE_365: 'office365/app/index/one_on_one/',
  DOWNLOAD_AUTO: 'download/auto/',
  CHAT: 'pmchat/chat/',
  FEED: 'pmchat/feed/',
  WEBAPP: 'pmwebng/',
  CALENDAR: 'pmcalendar/',
  CALENDAR_V2: 'pmcalendar/v2/index/',
  GANTT: 'gantt2/',
  IMPORT_CSV: 'project_templates/csv_import/pm/',
  GRAPH_PERMISSIONS: 'graph_api/access/microsoft-graph/',
  ONE_ON_ONE_HUB_GRAPH_PERMISSIONS: 'graph_api/access/microsoft-graph-oneonone/',
  PUBLIC_PROJECT_TEMPLATES: 'project_templates/public_templates',
  VERSION_CONTROL: 'chat/item_diff/',
  NOTES_COLLABORATION: 'collab/',
  EMBED_WEBAPP: 'pmweb/embed/',
  CHECKOUT: 'checkout/',
  AUTO_EXTEND_TRIAL7: 'sync/autoextend_trial7',
  PROMOTE_CONNECTORS: 'task_connectors/',
  WEBINARS: 'webinars/',
  EMAIL_PREFERENCES: 'accounts/unsubscribe/',
  RESTORE_PROJECTS: 'restore_projects/',
}

export let GRAPH_URLS = {
  EMAILS: 'v1.0/me/messages',
  EVENTS: 'v1.0/me/events',
  CALENDAR_VIEW_EVENTS: 'v1.0/me/calendar/calendarView',
  FILES: 'v1.0/me/drive/root',
  OUTLOOK_TASKS: '/beta/me/outlook/tasks',
  PLANS: 'v1.0/me/planner/plans',
  PLANNER_TASKS: 'v1.0/planner/plans/${ id }/tasks',
  SHARED_FILES: "v1.0/users/${ username }/drive/root/search(q='')",
  SHARED_FILES_WITH_ME: '/v1.0/me/drive/sharedWithMe',
  USERS: 'v1.0/users',
  USER_PROFILE_PICTURE: 'v1.0/users/${ id }/photo/$value',
}

PM_API_URLS = _.mapValues(PM_API_URLS, _prependBaseURL)
SERVER_URLS = _.mapValues(SERVER_URLS, _prependBaseURL)
GRAPH_URLS = _.mapValues(GRAPH_URLS, _prependGraphBaseURL)

export const AMPLITUDE_PROXY = removeProtocol(_prependBaseURL('m3trics'))

/**
 * Name of the resources. Used in API actions
 */
export const PM_API_RESOURCES = {
  CONFIG: 'CONFIG',
  GET_TOKEN: 'OAUTH2_TOKEN',
  ME: 'ME',
  LICENSE_STATUS: 'LICENSE_STATUS',
  INBOX_PLUS: 'INBOX_PLUS',
  INBOX_PLUS_PARTIAL: 'INBOX_PLUS_PARTIAL',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  SIGN_OUT: 'SIGN_OUT',
  COLLABORATORS: 'COLLABORATORS',
  MS_COLLABORATORS: 'MS_COLLABORATORS',
  ONE_ON_ONE_HUB_COLLABORATORS: 'ONE_ON_ONE_HUB_COLLABORATORS',
  PLATFORMS: 'PLATFORMS',
  COMMENT: 'COMMENT',
  ALL_COMMENTS: 'ALL_COMMENTS',
  ALL_COMMENTS_WITH_ITEM_SUMMARY: 'ALL_COMMENTS_WITH_ITEM_SUMMARY',
  RECENTLY_VISITED_COMMENTS: 'RECENTLY_VISITED_COMMENTS',
  RECENTLY_TYPED_COMMENTS: 'RECENTLY_TYPED_COMMENTS',
  ITEM_COMMENTS: 'ITEM_COMMENTS',
  ATTENTION_NEEDED: 'ATTENTION_NEEDED',
  ALL_ITEMS: 'ALL_ITEMS',
  ITEM: 'ITEM',
  ITEMS: 'ITEMS',
  ITEM_SUMMARIES: 'ITEM_SUMMARIES',
  ITEM_POST: 'ITEM_POST',
  ITEMS_POST: 'ITEMS_POST',
  ITEM_PUT: 'ITEM_PUT',
  ITEMS_PUT: 'ITEMS_PUT',
  RELATED_ITEMS: 'RELATED_ITEMS',
  POST_FILE: 'POST_FILE',
  FILE_ONEDRIVE_FOLDER: 'FILE_ONEDRIVE_FOLDER',
  DELETE_FILE: 'DELETE_FILE',
  ATTACH_EMAIL: 'ATTACH_EMAIL',
  MAKE_ITEM_PUBLIC: 'MAKE_ITEM_PUBLIC',
  MAKE_ITEM_PRIVATE: 'MAKE_ITEM_PRIVATE',
  INVITE_FOLLOWER_TO_ITEM: 'INVITE_FOLLOWER_TO_ITEM',
  UNINVITE_FOLLOWER_TO_ITEM: 'UNINVITE_FOLLOWER_TO_ITEM',
  ALL_PROJECTS: 'ALL_PROJECTS',
  ALL_PROJECT_SUMMARIES: 'ALL_PROJECT_SUMMARIES',
  USER_GROUPS: 'USER_GROUPS',
  SORT_PROJECTS: 'SORT_PROJECTS',
  PROJECT: 'PROJECT',
  PROJECT_POST: 'PROJECT_POST',
  PROJECT_PUT: 'PROJECT_PUT',
  ITEMS_IN_PROJECT: 'ITEMS_IN_PROJECT',
  FOLLOWED_ITEMS: 'FOLLOWED_ITEMS',
  ITEM_SUMMARIES_IN_PROJECT: 'ITEM_SUMMARIES_IN_PROJECT',
  PROJECT_READONLY_TOKEN: 'PROJECT_READONLY_TOKEN',
  SEARCH: 'SEARCH',
  ONE_ON_ONE: 'ONE_ON_ONE',
  CONNECTOR: 'CONNECTOR',
  CONNECTORS: 'CONNECTORS',
  TAB: 'TAB',
  TAB_PUT: 'TAB_PUT',
  TABS: 'TABS',
  RENAME_TAG: 'RENAME_TAG',
  TAG_ADD: 'TAG_ADD',
  TAG_REMOVE: 'TAG_REMOVE',
  ITEM_TAGS: 'ITEM_TAGS',
  PROJECT_TAGS: 'PROJECT_TAGS',
  ALL_SHARING_REQUESTS: 'ALL_SHARING_REQUESTS',
  SHARING_REQUEST: 'SHARING_REQUEST',
  SHARING_REQUEST_POST: 'SHARING_REQUEST_POST',
  SHARING_REQUEST_PUT: 'SHARING_REQUEST_PUT',
  REQUEST_ACCESS: 'REQUEST_ACCESS',
  IMPORT_PROJECT_CSV: 'IMPORT_PROJECT_CSV',
  TRACKING: 'TRACKING',
  DEVICE_GCM_POST: 'DEVICE_GCM_POST',
  DEVICE_APNS_POST: 'DEVICE_APNS_POST',
  CUSTOM_PROJECT_TEMPLATES: 'CUSTOM_PROJECT_TEMPLATES',
  CUSTOM_PROJECT_TEMPLATE: 'CUSTOM_PROJECT_TEMPLATE',
  INSTANTIATE_PROJECT_TEMPLATES: 'INSTANTIATE_PROJECT_TEMPLATES',
  PUBLIC_PROJECT_TEMPLATES: 'PUBLIC_PROJECT_TEMPLATES',
  PUBLIC_PROJECT_TEMPLATE: 'PUBLIC_PROJECT_TEMPLATE',
  PUBLIC_PROJECT_TEMPLATE_CATEGORIES: 'PUBLIC_PROJECT_TEMPLATE_CATEGORIES',
  MEETINGS: 'MEETINGS',
  MEETING: 'MEETING',
  NEAREST_MEETING: 'NEAREST_MEETING',
  MEETING_ITEMS: 'MEETING_ITEMS',
  ASSOCIATED_LINKS: 'ASSOCIATED_LINKS',
  DELETE_ASSOCIATED_LINKS: 'DELETE_ASSOCIATED_LINKS',
  REMINDER: 'REMINDER',
  REMINDER_POST: 'REMINDER_POST',
  REMINDER_PUT: 'REMINDER_PUT',
  UPDATE_PROJECT_ROLE: 'UPDATE_PROJECT_ROLE',
  INBOX_ADDRESSES: 'INBOX_ADDRESSES',
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SYNC_TZ_OFFSET: 'SYNC_TZ_OFFSET',
  ITEM_CONFLICTS: 'ITEM_CONFLICTS',
  PROJECT_SUBSCRIPTIONS: 'PROJECT_SUBSCRIPTIONS',
  CUSTOM_FILTERS: 'CUSTOM_FILTERS',
  CUSTOM_FILTERS_POST: 'CUSTOM_FILTERS_POST',
  CUSTOM_FILTERS_DELETE: 'CUSTOM_FILTERS_DELETE',
  CREATE_PROJECT_AI: 'CREATE_PROJECT_AI',
  GOAL_EXAMPLES: 'GOAL_EXAMPLES',
}

export const PM_API_RESOURCE_TYPE = {
  PROJECT: 'project',
  PROJECT_SUMMARY: 'project_summary',
  ITEM_SUMMARY: 'item_summary',
  ITEM: 'item',
  FILE: 'file',
  USER: 'user',
  TAG: 'tag',
  COMMENT: 'comment',
  SHARING_REQUEST: 'sharing_request',
  CUSTOM_PROJECT_TEMPLATE: 'template',
  PUBLIC_PROJECT_TEMPLATE: 'public_template',
  MEETING: 'meeting',
  TAB: 'teams_tab',
  REMINDER: 'reminder',
  NOTIFICATION: 'notification',
}

const _staticResource = {
  PM128: 'static/pm-128.png',
}

export const STATIC_RESOURCE = _.mapValues(_staticResource, _prependBaseURL)

export const PLATFORMS = {
  WINDOWS: 'windows',
}

export const TRACKED_PLATFORMS = {
  WINDOWS: 'WinTeamPaid',
  MAC_PROFESSIONAL: 'MacProfessional',
  MAC: 'Mac',
  WEB: 'pmwebng',
  OUTLOOK365: 'pm-office',
  TEAMS: 'pm-ms-teams',
  OUTLOOK_MOBILE: 'pm-outlook-mobile',
  IPHONE: 'iphoneteam-pro',
  CHROME: 'chrome',
}

export const TRACKED_DESKTOP_PLATFORMS = [
  TRACKED_PLATFORMS.WINDOWS,
  TRACKED_PLATFORMS.MAC_PROFESSIONAL,
  TRACKED_PLATFORMS.MAC,
]

export const APIS = {
  PM: 'PMAPI',
  GRAPH_PROXY: 'GRAPH_PROXY',
  PM_TEAMS_HELPER: 'PM_TEAMS_HELPER',
}

export const GRAPH_RESOURCES = {
  CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  DRIVE_FILES: 'DRIVE_FILES',
  EXCHANGE_TOKEN: 'EXCHANGE_TOKEN',
  GRAPH_COLLABORATORS: 'GRAPH_COLLABORATORS',
  GROUP_MEMBERS: 'GROUP_MEMBERS',
  OUTLOOK_EMAILS: 'OUTLOOK_EMAILS',
  OUTLOOK_TASKS: 'OUTLOOK_TASKS',
  PLANNER_PLANS: 'PLANNER_PLANS',
  PLANNER_TASKS: 'PLANNER_TASKS',
  PM_COLLABORATORS_IN_GROUP: 'PM_COLLABORATORS_IN_GROUP',
  SHARE_NEW_TEAMS_TAB: 'SHARE_NEW_TEAMS_TAB',
  SHARED_FILES: 'SHARED_FILES',
}

export const PM_TEAMS_HELPER_RESOURCES = {
  MEMBERS_IN_CONVERSATION: 'MEMBERS_IN_CONVERSATION',
  MATCHED_MEETING: 'MATCHED_MEETING',
}

const _PM_WS_URLS = {
  CHANGES: 'realtime/changes/',
  ITEM: 'realtime/comments/item/',
  PROJECT: 'realtime/comments/project/${ pid }/',
}

export const QUERY_PARAMS = {
  MS_TEAMS: 'msteams',
  OUTLOOK: 'outlook',
  PLATFORM: 'platform',
  EMBEDDED: 'embedded',
  NO_REDIRECT: 'noredirect',
  FAST_PROJECT: 'fastproject',
  ANDROID: 'android',
  ONE_ON_ONE_CREATOR: 'one_on_one_creator',
  ONE_ON_ONE_FIXED: 'one_on_one_fixed',
  PRINT: 'print',
}

// TODO: Deprecated, using KEY_CODE defined on keyboardHelper
// Unused in webapp
export const KEY_CODES = {
  ENTER: 13,
}

export const PM_WS_URLS = _.mapValues(_PM_WS_URLS, _prependWsBaseURL)
export const getAvatarURIForEmail = email => (email ? _baseURL + 'avatar/' + email : null)
export const getReadOnlyLinkForProject = (projectIdd, fastProject, print = false, embedded = false) =>
  `${_baseURL}pmwebng/read_only/?nosidebar${!print ? '&print=0' : ''}${
    embedded ? '&embedded=1' : ''
  }#?projectIdd=${projectIdd}&fastproject=${fastProject}`
export const getSingleProjectLink = (projectIdd, print = false, embedded = false) =>
  `${_baseURL}office365/app/index/matrix/${projectIdd}/?singleProject=1${!print ? '&print=0' : ''}${
    embedded ? '&embedded=1' : ''
  }`
export const getMeetingLink = meetingID => `${_baseURL}office365/app/index/meeting/${optionalPath(meetingID)}`
export const getItemLink = itemId => `${_baseURL}native/api/v1/item/${itemId}/`
export const getItemChatLink = itemId => `${_baseURL}pmchat/chat/${itemId}/`
export const getProjectFeedLink = (projectId, initDays) => `${_baseURL}pmchat/feed/${projectId}/?init_days=${initDays}`
export const getProjectLink = projectId => `${_baseURL}native/api/v1/project/${projectId}/`
export const getOneOnOneURLForEmail = email => `${_baseURL}accounts/oneonone/${email}/?embedded=1`
export const getOneOnOneMultiItemURLForEmail = (email, embedded = true, noopen = false) => {
  const query = encodeURIComponent(
    `/api/v1/item/?format=json&returnfollowers=1&limit=100&state__lte=1&oneononewith=${email}`
  )
  let u = `${_baseURL}multi_item_report/?query=${query}&title=One on one: ${email}`
  if (embedded) {
    u += '&embedded=1'
  }
  if (noopen) {
    u += '&noopen=1'
  }
  return u
}
export const getIconURL = icon => `${_baseURL}static/icons/${icon}`
export const getFileExtensionIconURL = filename => `${_baseURL}doc_icon/${filename}`
export const getCSVExportURL = pid => `${_baseURL}project_templates/csv_export/${pid}/`
export const getCSVExportSelectionURL = pid => `${_baseURL}project_templates/csv_export/items/`
export const getCSVImportToProjectURL = pid => `${_baseURL}project_templates/csv_import/pm/${pid}/`

export const getOauthLoginURL = clientID =>
  `${_baseURL}o/authorize/?client_id=${clientID}&ref=pmoutlook&response_type=code`

const getNextQueryParamFromCurrentLocation = () => encodeURIComponent(window.location.pathname + window.location.search)
export const getLoginWithCurrentLocationAsNext = (isEmbedded, isEmbeddedOnTeams) => {
  const next = getNextQueryParamFromCurrentLocation()
  return getLoginWithNext(next, isEmbedded, isEmbeddedOnTeams)
}

export const getLoginWithNext = (next, isEmbedded, isEmbeddedOnTeams, loginPath = null) => {
  if (!next) {
    return
  }
  if (isEmbedded && !isEmbeddedOnTeams) {
    // Not to open login in the browsers
    // for apps as: macOS, windows, ...
    return
  }

  if (loginPath) {
    return `${BASE_URL}${loginPath}`
  }

  if (isEmbeddedOnTeams) {
    return `${BASE_URL}office365/teams/index/loginRequired/?next=${next}`
  } else {
    return `${BASE_URL}accounts/login/?next=${next}`
  }
}

export const getProtocolLinkForItemWithId = id => getProtocolLinkForResourceWithId('item', id)
export const getProtocolLinkForProjectWithId = id => getProtocolLinkForResourceWithId('project', id)
export const getProtocolLinkForResourceWithId = (resource, id) =>
  `prioritymatrix://sync.appfluence.com/api/v1/${resource}/${id}/`

//deprecated Prefer SERVER_URLS.CHECKOUT
export const PRICING_URL = 'https://appfluence.com/pricing'

export const SUBSCRIBE_CALENDAR_URL =
  'https://appfluence.com/productivity/calendar-feeds-outlook-google-more/#subscribe'

/**
 * This are the platforms that wrap our webapp. 'standalone' suffix means it's an app that lives outside app stores.
 */
export const WEBAPP_PM_PLATFORMS = {
  ANDROID_WEBVIEW: 'androidwebview',
  IOS_WEBVIEW: 'ioswebview',
  ELECTRON_MAC: 'electronmac',
  ELECTRON_WIN: 'electronwin',
  ELECTRON_MAC_STANDALONE: 'electronmacstandalone',
  ELECTRON_WIN_STANDALONE: 'electronwinstandalone',
}

export const isStorePlatform = platform =>
  [
    WEBAPP_PM_PLATFORMS.ANDROID_WEBVIEW,
    WEBAPP_PM_PLATFORMS.IOS_WEBVIEW,
    WEBAPP_PM_PLATFORMS.ELECTRON_MAC,
    WEBAPP_PM_PLATFORMS.ELECTRON_WIN,
  ].includes(platform)
export const isEmbeddedPlatform = platform => Object.values(WEBAPP_PM_PLATFORMS).includes(platform)

export const graphProfilePictureUrlUsingProxy = id => {
  const avatarUrl = _.template(GRAPH_URLS.USER_PROFILE_PICTURE)({ id })
  return `${PM_API_URLS.GRAPH_PROXY}?q=${encodeURIComponent(avatarUrl)}`
}

export const embedProjectView = ({ title, link }) => {
  return `${SERVER_URLS.EMBED_WEBAPP}?${objToQueryParamsString({ title, link })}`
}

export const PRODUCTION_WEBAPP = prodURL + 'office365/app/index/'
